import React, { FC, useReducer } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

// components
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      alignSelf: 'center',
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: `calc(100vh - 30px)`,
      width: `calc(100% - 50px - 1rem)`,
      maxWidth: 1750,
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    footer: {
      alignSelf: 'center',
      flexGrow: 1,
      padding: theme.spacing(3),
      width: `calc(100% - 50px - 1rem)`,
      maxWidth: 1750,
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
  })
);

// functional component
const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const [open, toggle] = useReducer((status) => !status, false);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={open} handleMenuOpen={toggle} />
      <Navigation open={open} handleMenuClose={toggle} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        {children}
      </main>
      <footer
        className={clsx(classes.footer, {
          [classes.contentShift]: open,
        })}
      >
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
