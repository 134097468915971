import { lazy } from 'react';

import DashboardIcon from '@material-ui/icons/BarChartOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BarChartIcon from '@material-ui/icons/BarChart';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';

import RouteItem from '../model/RouteItem.model';

const Configurations = lazy(() => import('../pages/wallet/Configurations'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Dividends = lazy(() => import('../pages/wallet/Dividends'));
const Login = lazy(() => import('../pages/Login'));
const MonthResult = lazy(() => import('../pages/reports/MonthResult'));
const OperationsClosed = lazy(() => import('../pages/wallet/OperationsClosed'));
const Patrimony = lazy(() => import('../pages/wallet/Patrimony'));
const Register = lazy(() => import('../pages/Register'));
const Support = lazy(() => import('../pages/support'));
const Transactions = lazy(() => import('../pages/wallet/Transactions'));

export const privateRoutes: Array<RouteItem> = [
  {
    id: 'router-dashboard',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    path: '/',
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    id: 'router-transactions',
    title: 'Operations',
    tooltip: 'Operations',
    path: '/carteira/operacoes',
    component: Transactions,
    icon: ReceiptIcon,
  },
  {
    id: 'router-transactions-closed',
    title: 'OperationsClosed',
    tooltip: 'OperationsClosed',
    path: '/carteira/resultados',
    component: OperationsClosed,
    icon: AccountBalanceWalletIcon,
  },
  {
    id: 'router-patrimony',
    title: 'Patrimony',
    tooltip: 'Patrimony',
    path: '/carteira/patrimonio',
    component: Patrimony,
    icon: AccountBalanceIcon,
  },
  {
    id: 'router-dividends',
    title: 'Dividends',
    tooltip: 'Dividends',
    path: '/carteira/dividendos',
    component: Dividends,
    icon: AttachMoneyIcon,
  },
  {
    id: 'router-reports',
    title: 'Reports',
    tooltip: 'Reports',
    component: MonthResult,
    icon: AssessmentIcon,
    subRoutes: [
      {
        id: 'router-reports-month-result',
        title: 'MonthResult',
        tooltip: 'MonthResult',
        path: '/relatorios/resultado/mensal',
        component: MonthResult,
        icon: BarChartIcon,
      },
    ],
  },
  {
    id: 'router-configurations',
    title: 'Configurations',
    tooltip: 'Configurations',
    path: '/carteira/configuracao',
    component: Configurations,
    icon: SettingsIcon,
  },
  {
    id: 'router-suppert',
    title: 'Support',
    tooltip: 'Support',
    path: '/suporte',
    component: Support,
    icon: ContactSupportIcon,
  },
];

export const publicRoutes: Array<RouteItem> = [
  {
    id: 'router-login',
    title: 'Login',
    tooltip: 'Login',
    path: '/login',
    component: Login,
  },
  {
    id: 'router-register',
    title: 'Register',
    tooltip: 'Register',
    path: '/cadastro',
    component: Register,
  },
];
