const TRANSLATIONS_PTBR = {
  Action: 'Ação',
  ActionOrigin: 'Ação Origem',
  Add: 'Adicionar',
  AddOperataion: 'Adicionar Operação',
  Adjust: 'Ajustar',
  AllPeriod: 'Todos',
  Amount: 'Quantidade',
  AmountCei: 'Quantidade CEI',
  AmountPreview: 'Quantidade Prevista',
  AmountWallet: 'Quantidade Carteira',
  at: 'em',
  AvaragePrice: 'Preço Médio',
  Assets: 'Ativos',
  BankStatement: 'Extrato',
  bdr: 'BDR',
  buy: 'Compra',
  Buy: 'Compra',
  Brokerage: 'Corregatem',
  BrokerageNote: 'Nota de Corregatem',
  BrokeragesNotSent:
    'Error ao enviar as notas para processamento. Tente novamente mais tarde!',
  BrokeragesSentWait:
    'Notas enviadas para processamento. Aguarde a atualização da carteira!',
  BROKERAGE: 'Nota',
  CleanWallet: 'Apagar dados',
  CleanWalletConfirmation:
    'Deseja realmente apagar todos os dados da sua carteira?',
  CleanWalletErro:
    'Error ao enviar ao apagar os dados. Tente novamente mais tarde!',
  CleanWalletSuccessfully: 'Dados apagados com sucesso',
  Configurations: 'Configurações',
  coin: 'Criptomoeda',
  Connect: 'Conectar',
  Connected: 'Conectado',
  ConnectionCeiErro: 'Não foi possível conectar na conta: ',
  ConnectionCeiSuccessfully:
    'Conta CEI conectada com sucesso!. Aguarde a sincronicação dos dados.',
  CreatedAt: 'Criado em',
  CurrentMonth: 'Mês atual',
  CurrentYear: 'Ano atual',
  Composition: 'Composição',
  CostAcquisition: 'Custo Aquisição',
  Date: 'Data',
  DateClose: 'Fechada em',
  DateOpen: 'Aberta em',
  DeclaredDate: 'Data com',
  Delete: 'Apagar',
  DeleteTransaction: 'Você tem certeza que deseja remover essa operação?',
  Disclamer:
    'Todas as informações apresentadas tem caráter informativo e são provenientes de fontes públicas como B3, CVM, TESOURO NACIONAL, etc. e de dados calculados a partir das informações coletadas. Não temos o objetivo de fazer sugestão de compra ou venda de ativos, sendo assim, não se responsabiliza pelas decisões e caminhos tomados a partir da análise das informações aqui apresentadas.',
  Disconnect: 'Desconectar',
  Disconnected: 'Desconectada',
  Dividends: 'Dividendos',
  dividends: 'Dividendos',
  DropzoneBrokerages: 'Arraste ou selecione até 10 arquivos',
  DueDate: 'Data Vencimento',
  DUEDATE: 'Vencida',
  estate: 'FII',
  Expenses: 'Despesas',
  Email: 'E-mail',
  EmailAlreadyRegistred: 'E-mail já cadastrado.',
  EmissionValue: 'Valor de Emissão',
  Fees: 'Taxas',
  ForgoutPassword: 'Esqueceu sua senha?',
  FooterText:
    'Copyright © 2021 Apliquei! E Agora? Todos os direitos reservados',
  Forecast: 'Previsão',
  GoodAfternoon: 'Boa tarde',
  GoodMorning: 'Bom dia',
  GoodNight: 'Boa noite',
  Growth: 'Crescimento',
  HighLow: 'Altas/Baixas',
  Inconsistencies: 'Inconsistências',
  inProgress: 'Atuando',
  InvalidPassword: 'Senha inválida',
  Issues: 'Chamados',
  IssueClosedSuccessfully: 'Chamado finalizado',
  IssueCloseError: 'Erro ao finalizar o chamado. Tente novamente mais tarde!',
  LastMonth: 'Último mês',
  LastProcess: 'Último processamento',
  Last6Month: 'Últimos 6 meses',
  LastYear: 'Último ano',
  LengthPassword: 'Sua senha deve ter mais que 6 caractres.',
  Login: 'Login',
  LoginFailed: 'Falha ao logar',
  Logoff: 'Sair',
  MANUAL: 'Manual',
  Month: 'Mês',
  MonthHistory: 'Histórico Mensal',
  MonthResult: 'Resultado Mensal',
  Name: 'Nome',
  NewIssue: 'Novo Chamado',
  NextMonth: 'Próximo mês',
  No: 'Não',
  NoHasAccount: 'Ainda não tem sua conta? Criar agora!',
  OpenAt: 'Aberto em',
  Operations: 'Operações',
  OperationsClosed: 'Operações Encerradas',
  OperationsWins: 'Lucro com operações',
  option: 'Opções',
  Origin: 'Origem',
  Password: 'Senha',
  Patrimony: 'Patrimônio',
  PayDate: 'Data Pagamento',
  pending: 'Pendente',
  Percentage: 'Percentual',
  PercentDay: 'Variação Dia',
  PercentInGroup: '% No Grupo',
  PercentWallet: '% Carteira',
  Price: 'Preço',
  PriceClose: 'Preço Fechado',
  PriceOpen: 'Preço Aberto',
  Profitability: 'Rentabilidade',
  Process: 'Processar',
  Quantity: 'Quantidade',
  Quotation: 'Cotação',
  Register: 'Cadastrar',
  Rentability: 'Rentabilidade',
  Reports: 'Relatórios',
  ReprocessTransactions: 'Reprocessar Transações',
  Result: 'Resultado',
  ResultDay: 'Resultado Dia',
  ResultPercent: 'Variação Patrimônio',
  Save: 'Salvar',
  sell: 'Venda',
  Sell: 'Venda',
  SixMonths: '6 Meses',
  ScheduleMonth: 'Agenda Mensal',
  SPLIT: 'Desdobramento',
  stock: 'Ações',
  stockusa: 'Ações USA',
  StockType: 'Tipo Ativo',
  subscription: 'Subscrição',
  Subscriptions: 'Subscrições',
  SubscriptionValue: 'Valor de subscrição',
  success: 'Sucesso',
  Support: 'Suporte',
  SwitchDarkMode: 'Alterar para thema escuro',
  SwitchLightMode: 'Alterar para thema claro',
  Ticker: 'Ativo',
  Title: 'Titulo',
  To: 'Até',
  Today: 'Hoje',
  Total: 'Total',
  TotalCost: 'Custo Total',
  TotalDividends: 'Proventos acumulados',
  TotalNetValue: 'Total liquído',
  TransactionCreatedWait:
    'Operação criada com sucesso. Aguarde a atualização da carteira!',
  TransactionDeleted: 'Operação removida!',
  TransactionNotCreated:
    'Error ao criar a Operação. Tente novamente mais tarde!',
  TransactionNotDeleted:
    'Error ao remover a Operação. Tente novamente mais tarde!',
  Type: 'Tipo',
  TwelveMonths: '12 Meses',
  TwentyFourMonths: '24 Meses',
  unknown: 'Erro inesperado',
  UpdatedAt: 'Atualizado em',
  User: 'Usuario',
  Username: 'Usuário',
  Value: 'Valor',
  ValueIncrease: 'Valor Aplicado',
  Yes: 'Sim',
  YieldCost: 'YoC',
  Wallet: 'Carteira',
  WalletConfigurations: 'Configurações da Carteira',
  WalletOnReprocess:
    'Sua carteira esta sendo reprocessada. Aguarde a atualização!',
  WalletReprocessErro:
    'Error ao reprocessa a carterira. Tente novamente mais tarde!',
  WithoutInconsistencies: 'Sem inconsistências',
  WrongCredentials: 'Dados de acesso inválidos',
};

export default TRANSLATIONS_PTBR;
