import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import TRANSLATIONS_PTBR from './ptBR/translations';
import TRANSLATIONS_EN from './en/translations';

i18n.use(initReactI18next).init({
  resources: {
    enUS: {
      translation: TRANSLATIONS_EN,
    },
    ptBR: {
      translation: TRANSLATIONS_PTBR,
    },
  },
});

i18n.changeLanguage('ptBR');
