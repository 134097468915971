import React from 'react';

import User from '../interfaces/business/userInterface';

export interface SessionContextProps {
  logout: () => void;
  getUser: () => User;
  toggleTheme: () => void;
}

export const createDefaultUser = (): SessionContextProps => {
  const logout = () => {};
  const toggleTheme = () => {};
  const getUser = () => JSON.parse(localStorage.getItem('appUser') || '{}');

  return { logout, getUser, toggleTheme };
};

export const SessionContext = React.createContext<SessionContextProps>(
  createDefaultUser()
);

export const SessionProvider: React.FC<{
  toggleTheme: () => void;
}> = ({ children, toggleTheme }) => {
  const getUser = () => JSON.parse(localStorage.getItem('appUser') || '{}');

  const logout = () => {
    localStorage.removeItem('appUser');
    window.location.href = '/login';
  };

  return (
    <SessionContext.Provider value={{ getUser, logout, toggleTheme }}>
      {children}
    </SessionContext.Provider>
  );
};
