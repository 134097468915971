import React, { ReactElement, FC } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../common/sessionContext';
import { APP_TITLE } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: 250,
      width: `calc(100% - 250px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
  })
);

// define interface to represent component props
interface Props {
  open: boolean;
  handleMenuOpen: () => void;
}

const Header: FC<Props> = ({ open, handleMenuOpen }): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const loginContext = React.useContext(SessionContext);

  const handleLogout = () => {
    loginContext.logout();
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={handleMenuOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              size="small"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {APP_TITLE}
            </Typography>
          </div>
          <IconButton onClick={loginContext.toggleTheme}>
            {loginContext.getUser()?.theme !== 'dark' ? (
              <Tooltip title={t('SwitchDarkMode') as string} placement="bottom">
                <Brightness3Icon />
              </Tooltip>
            ) : (
              <Tooltip
                title={t('SwitchLightMode') as string}
                placement="bottom"
              >
                <Brightness7Icon />
              </Tooltip>
            )}
          </IconButton>
          <IconButton size="small" color="inherit" onClick={handleLogout}>
            <Tooltip title={t('Logoff') as string} placement="bottom">
              <ExitToAppIcon />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
