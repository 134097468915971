import React, { FC, ReactElement, useState } from 'react';
import clsx from 'clsx';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Icon,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/FileCopy';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import MenuItem from './MenuItem';
// app routes
import { privateRoutes } from '../common';

// interfaces
import RouteItem from '../model/RouteItem.model';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    nested: {
      marginLeft: theme.spacing(2),
    },
    selected: {
      transition: 'box-shadow',
      transitionDuration: '1s',
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
    },
    subMenuOpen: {
      width: 250,
      position: 'fixed',
      top: 57,
      height: '100%',
      background: theme.palette.background.paper,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

// functional component
const Menu: FC = (): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();
  const { t } = useTranslation();

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <List
      key="menu"
      className={clsx({
        [classes.subMenuOpen]: open,
      })}
    >
      {privateRoutes.map((route: RouteItem) => (
        <div key={`main-${route.id}`}>
          {route.subRoutes ? (
            <div key={`sub-${route.id}`}>
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <Tooltip title={t(route.tooltip) || ''} placement="right">
                    <IconButton
                      className={clsx({
                        [classes.selected]:
                          !open &&
                          route.subRoutes.some(
                            (item: RouteItem) => item.path === location.pathname
                          ),
                      })}
                      size="small"
                    >
                      <Icon component={route.icon || DefaultIcon} />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(route.title)} />
                {open ? (
                  <Tooltip title="Collapse" placement="bottom">
                    <ExpandLess />
                  </Tooltip>
                ) : (
                  <Tooltip title="Expand" placement="bottom">
                    <ExpandMore />
                  </Tooltip>
                )}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List className={classes.nested}>
                  {route.subRoutes.map((sRoute: RouteItem) => (
                    <MenuItem
                      key={`menu-sub-${sRoute.id}`}
                      id={`${sRoute.id}`}
                      title={sRoute.title}
                      icon={sRoute.icon}
                      tooltip={sRoute.tooltip}
                      path={sRoute.path}
                      component={sRoute.component}
                      subRoutes={sRoute.subRoutes}
                      onClick={handleClick}
                    />
                  ))}
                </List>
              </Collapse>
            </div>
          ) : (
            <MenuItem
              key={`menu-${route.id}`}
              id={`${route.id}`}
              title={route.title}
              icon={route.icon}
              tooltip={route.tooltip}
              path={route.path}
              component={route.component}
              subRoutes={route.subRoutes}
              onClick={() => {
                setOpen(false);
              }}
            />
          )}
        </div>
      ))}
    </List>
  );
};

export default Menu;
