import { createTheme, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    assets: {
      bdr: React.CSSProperties['color'];
      estate: React.CSSProperties['color'];
      option: React.CSSProperties['color'];
      stock: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    assets: {
      bdr: React.CSSProperties['color'];
      estate: React.CSSProperties['color'];
      option: React.CSSProperties['color'];
      stock: React.CSSProperties['color'];
    };
  }
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    footer: Palette['primary'];
    negative: Palette['primary'];
    postive: Palette['primary'];
  }
  interface PaletteOptions {
    footer: PaletteOptions['primary'];
    negative: PaletteOptions['primary'];
    postive: PaletteOptions['primary'];
  }
}

const assets = {
  bdr: '#ad4203',
  coin: '#edba20',
  estate: '#1d6e04',
  option: '#ad1703',
  stock: '#0d00ff',
  stockusa: '#8d1920',
};

// define light theme colors
export const lightTheme: Theme = createTheme({
  typography: { fontSize: 14 },
  palette: {
    type: 'light',
    text: {
      primary: 'rgb(17, 24, 39)',
      secondary: 'rgb(107, 114, 128)',
      disabled: 'rgb(149, 156, 169)',
    },
    primary: {
      light: '#BFC4E6',
      main: '#2A3BAB',
      dark: '#0F1980',
    },
    secondary: {
      light: '#C2ECF0',
      main: '#33C1CD',
      dark: '#149EAE',
      contrastText: '#1E1F23',
    },
    background: {
      paper: '#FFFFFF',
      default: '#EDF0F6',
    },
    footer: { main: '#000000' },
    negative: { main: '#ba0404' },
    postive: { main: '#1ca628' },
    error: red,
  },
  assets,
});

// define dark theme colors
export const darkTheme: Theme = createTheme({
  typography: { fontSize: 14 },
  palette: {
    type: 'dark',
    text: {
      primary: 'rgb(255,255,255)',
      secondary: 'rgb(229, 231, 235)',
      disabled: 'rgb(156, 163, 175)',
    },
    primary: {
      main: '#2A3BAB',
      50: '#e5e6e8',
      100: '#bec1c5',
      200: '#92979f',
      300: '#666d78',
      400: '#464e5b',
      500: '#252f3e',
      600: '#212a38',
      700: '#1b2330',
      800: '#161d28',
      900: '#0d121b',
      A100: '#5d8eff',
      A200: '#2a6aff',
      A400: '#004af6',
      A700: '#0042dd',
    },
    secondary: {
      light: '#bdf2fa',
      main: '#22d3ee',
      dark: '#0cb7e2',
    },
    background: {
      paper: '#121212',
      default: '#1E2125',
    },
    footer: { main: '#2A3BAB' },
    negative: { main: '#ff1100' },
    postive: { main: '#0eed22' },
    error: red,
  },
  assets,
});
