import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import LinearProgress from '@material-ui/core/LinearProgress';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './translations/i18n';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('apliquei-e-agora/aea-fe');
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <LinearProgress />
        </div>
      }
    >
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
