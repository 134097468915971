// APP TEXT
export const APP_TITLE = 'Apliquei! E Agora?';

// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_DASHBOARD = 'Dashboard';
export const PAGE_TITLE_GH_PRIVATE = 'GitHub Private';
export const PAGE_TITLE_GH_PUBLIC = 'GitHub Public';
export const PAGE_TITLE_CODE = 'Code Editor';
export const PAGE_TITLE_SETTINGS = 'Settings';

// UI CONSTANTS
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
