import React, { useReducer } from 'react';
import { SnackbarProvider } from 'notistack';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@material-ui/pickers';
import {
  createTheme,
  Theme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Layout from './components/Layout';
import { lightTheme, darkTheme } from './theme/appTheme';
import {
  privateRoutes,
  publicRoutes,
  SessionContext,
  SessionProvider,
} from './common';
import RouteItem from './model/RouteItem.model';
import UserService from './services/userService';

function App(): JSX.Element {
  const loginContext = React.useContext(SessionContext);
  const userService = new UserService();

  const [useDefaultTheme, toggle] = useReducer(
    (theme) => !theme,
    loginContext.getUser()?.theme !== 'dark'
  );

  const toggleTheme = () => {
    toggle();
    userService.update({
      theme: !useDefaultTheme ? 'light' : 'dark',
    });
    localStorage.setItem(
      'appUser',
      JSON.stringify({
        ...loginContext.getUser(),
        theme: !useDefaultTheme ? 'light' : 'dark',
      })
    );
  };

  let theme: Theme = createTheme(
    useDefaultTheme ? lightTheme : darkTheme,
    locales.ptBR
  );
  theme = responsiveFontSizes(theme);
  return (
    <>
      <Helmet>
        <title>Apliquei! E agora?</title>
      </Helmet>
      <Router>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SessionProvider toggleTheme={toggleTheme}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                dateAdapter={DateFnsAdapter}
                locale={ptBRLocale}
              >
                <Switch>
                  {publicRoutes.map((route: RouteItem) =>
                    route.subRoutes ? (
                      route.subRoutes.map((item: RouteItem) => (
                        <Route
                          key={`${item.id}`}
                          path={`${item.path}`}
                          component={item.component}
                          exact
                        />
                      ))
                    ) : (
                      <Route
                        key={`${route.id}`}
                        path={`${route.path}`}
                        component={route.component}
                        exact
                      />
                    )
                  )}
                  <Layout>
                    {privateRoutes.map((route: RouteItem) =>
                      route.subRoutes ? (
                        route.subRoutes.map((item: RouteItem) => (
                          <Route
                            key={`${item.id}`}
                            path={`${item.path}`}
                            exact
                            render={() =>
                              loginContext.getUser()?.accessToken ? (
                                <item.component />
                              ) : (
                                <Redirect to="/login" />
                              )
                            }
                          />
                        ))
                      ) : (
                        <Route
                          key={`${route.id}`}
                          path={`${route.path}`}
                          render={() =>
                            loginContext.getUser()?.accessToken ? (
                              <route.component />
                            ) : (
                              <Redirect to="/login" />
                            )
                          }
                          exact
                        />
                      )
                    )}
                  </Layout>
                </Switch>
              </LocalizationProvider>
            </ThemeProvider>
          </SessionProvider>
        </SnackbarProvider>
      </Router>
    </>
  );
}

export default App;
