import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
      textAlign: 'center',
      minHeight: 30,
    },
    footer: {
      color: theme.palette.footer.main,
    },
  })
);

const Footer: FC = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Typography className={classes.footer}>{t('Disclamer')}</Typography>
      <Typography className={classes.footer}>{t('FooterText')}</Typography>
    </Box>
  );
};

export default Footer;
