/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import User from '../interfaces/business/userInterface';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    'content-type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config) => {
    if (
      !config?.url?.endsWith('login') ||
      !config?.url?.endsWith('refresh') ||
      !config?.url?.endsWith('signup') ||
      !config?.url?.endsWith('register')
    ) {
      const user: User = JSON.parse(localStorage.getItem('appUser') || '{}');
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${user.accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !error?.response?.config?.url.endsWith('login') &&
      !error?.response?.config?.url.endsWith('register')
    ) {
      localStorage.removeItem('appUser');
      window.location.href = '/login';
    }
    return Promise.reject(error?.response?.data);
  }
);
export default api;
