import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/FileCopy';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// models
import RouteItem from '../model/RouteItem.model';

// define css-in-js
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      transition: 'box-shadow',
      transitionDuration: '1s',
      boxShadow: `0 0 3px ${theme.palette.primary.main}, 0 0 9px ${theme.palette.primary.main}, 0 0 11px ${theme.palette.primary.main}, 0 0 30px ${theme.palette.primary.main}`,
    },
    nested: {
      marginLeft: theme.spacing(2),
    },
    listItemDisabled: {
      cursor: 'not-allowed',
    },
  })
);

// functional component
const MenuItem: FC<RouteItem> = (route: RouteItem): ReactElement => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: any = useLocation();

  const { path, id, tooltip, icon, title, onClick } = route;
  const { t } = useTranslation();

  return (
    <NavLink
      to={`${path}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
      key={`mi-${id}`}
      onClick={onClick}
    >
      <Tooltip title={t(tooltip) || ''} placement="right">
        <ListItem button>
          <ListItemIcon>
            <IconButton
              className={clsx({
                [classes.selected]: location.pathname === path,
              })}
              size="small"
            >
              <Icon component={icon || DefaultIcon} />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={t(title)} />
        </ListItem>
      </Tooltip>
    </NavLink>
  );
};

export default MenuItem;
