import { AxiosResponse } from 'axios';
import ReportInterface from '../interfaces/business/reportInterface';
import UserCeiInterface from '../interfaces/business/userCeiInterface';
import UserConfigurationsInterface from '../interfaces/business/userConfigurationsInterface';
import User from '../interfaces/business/userInterface';
import UserStatementInterface from '../interfaces/business/userStatementInterface';
import UserStatistics from '../interfaces/business/userStatisticsInterface';
import api from './api';

class UserService {
  deleteCei = (): Promise<AxiosResponse<UserCeiInterface>> => {
    return api.delete('/v1/user/cei');
  };

  getCei = (): Promise<AxiosResponse<UserCeiInterface>> => {
    return api.get('/v1/userCei');
  };

  getConfigurations = (): Promise<
    AxiosResponse<UserConfigurationsInterface>
  > => {
    return api.get('/v1/userConfigurations');
  };

  getReports = (): Promise<AxiosResponse<ReportInterface[]>> => {
    return api.get('/v1/userReports');
  };

  getStatement = (): Promise<AxiosResponse<UserStatementInterface[]>> => {
    return api.get('/v1/userStatement');
  };

  getStatistics = (): Promise<AxiosResponse<UserStatistics>> => {
    return api.get('/v1/userStatistics');
  };

  reset = (): Promise<AxiosResponse> => {
    return api.post('/v1/user/reset');
  };

  saveCei = (
    userCei: UserCeiInterface
  ): Promise<AxiosResponse<UserCeiInterface>> => {
    return api.put('/v1/user/cei', userCei);
  };

  update = (user: User): Promise<AxiosResponse<User>> => {
    return api.put('/v1/user', user);
  };
}

export default UserService;
